/**
 * Clear messages in the given box
 * @param  {jQuery object} msgBox
 */

$(document).ready(function(){
    $('.full-menu-btn').click(function(){
        $('.fullscreen-nav').toggleClass('open');
        $('.content-website').toggleClass('open-menu');
        $('.overlay-website').toggleClass('actif');
        $(this).toggleClass('close-btn'); 
    })
   
    $('.partner-menu-btn').click(function(){
        $('.fullscreen-partner-nav').toggleClass('open');
        $('.content-website').toggleClass('open-menu');
        $('.overlay-website').toggleClass('actif');
        $('.close').addClass('close-btn'); 
    })
    $('.close').click(function(){
        $('.fullscreen-partner-nav').removeClass('open');
        $('.content-website').removeClass('open-menu');
        $('.overlay-website').removeClass('actif');
        $('.close').removeClass('close-btn'); 
    })
});  

function clearMessages(msgBox) {
    "use strict";

    msgBox.hide()
        .attr('class', 'alert')
        .find('li').remove();
}
function afficheContent(IdShow, IdHide1, IdHide2, IdHide3){
    $('#'+IdShow).show();
    $('#'+IdHide1).hide();
    $('#'+IdHide2).hide();
    $('#'+IdHide3).hide();
}
$('.reseau-btn').click(function(){
    $('.reseau-btn').removeClass("active");
    $(this).addClass("active");
});

/**
 * Add message to the given box
 * @param {jQuery object} msgBox
 * @param {string} message
 * @param {string} success
 */
function addMessage(msgBox, message, success) {
    "use strict";

    msgBox.show()
        .addClass('alert-' + success)
        .find('ul').append('<li>' + message + '</li>')
        .slideDown();
}

// SEARCH BAR
$(document).ready(function(e){
    $('.search-panel .dropdown-menu').find('a').click(function(e) {
        e.preventDefault();
        var param = $(this).attr("href").replace("#","");
        var concept = $(this).text();
        $('.search-panel span#search_concept').text(concept);
        $('.input-group #search_param').val(param);
    });
});

// WOW ANIMATED
var wow = new WOW(
  {
    boxClass:     'wow',
    animateClass: 'animated',
    offset:       0,
    mobile:       true,
    live:         true,
    callback:     function(box) {
      // 
    },
    scrollContainer: null
  }
);
wow.init();

$(document).ready(function() {
    
    new WOW().init();

    // scroll #ancres & scoll Up
    $('a.scroll').on('click', function(evt){
        evt.preventDefault(); 

        var target = $(this).attr('href');

        $('html, body')
           .stop()
           .animate({scrollTop: $(target).offset().top}, 1000 );
    });

});

// SWIPE MOBILE
$(document).ready(function() {  
        $('#myCarousel').hammer().on('swipeleft', function(){
            $(this).carousel('next'); 
        })
        $('#myCarousel').hammer().on('swiperight', function(){
            $(this).carousel('prev'); 
        })
 });


// CONTENT CAROUSEL
$('#ca-coloration').contentcarousel();
$('#ca-accompagnement').contentcarousel();
$('#ca-best-sellers').contentcarousel();

$('#ca-carousel').contentcarousel();

$('#ca-innovation').contentcarousel({
    sliderSpeed     : 500,
    sliderEasing    : 'easeOutExpo',
    itemSpeed       : 500,
    itemEasing      : 'easeOutExpo',
    scroll          : 1 
});

// PRODUCT CAROUSEL
$('#productCarousel').carousel({
    interval: false
}); 

// handles the carousel thumbnails
$('[id^=product-carousel-selector-]').click( function(){
    var id_selector = $(this).attr("id");
    var id = id_selector.substr(id_selector.length -1);
    id = parseInt(id);
    $('#productCarousel').carousel(id);
    $('[id^=product-carousel-selector-]').removeClass('selected');
    $(this).addClass('selected');
});

// when the carousel slides, auto update
$('#productCarousel').on('slid', function (e) {
    var id = $('.item.active').data('slide-number');
    id = parseInt(id);
    $('[id^=product-carousel-selector-]').removeClass('selected');
    $('[id=product-carousel-selector-'+id+']').addClass('selected');
});

// LIGHTBOX
$(document).on('click', '[data-toggle="lightbox"]', function(event) {
    event.preventDefault();
    $(this).ekkoLightbox();
});

// IMAGES WITH TOOLTIP
$(document).ready(function(){

// Sens de l'infobulle
var tooltipDirection;
             
for (i=0; i<$(".pin").length; i++)
{               
    // Définir le sens de l'infobulle - haut ou bas
    if ($(".pin").eq(i).hasClass('pin-down')) {
        tooltipDirection = 'tooltip-down';
    } else {
        tooltipDirection = 'tooltip-up';
        }

    // Insérer l'infobulle
    $("#tooltip-img").append("<div style='left:"+$(".pin")
        .eq(i).data('xpos')+"%;top:"+$(".pin")
        .eq(i).data('ypos')+"%' class='" + tooltipDirection +"'>\
                <div class='tooltip'>" + $(".pin").eq(i).html() + "</div>\
            </div>");
} 
for (i=0; i<$(".pin2").length; i++)
{               
    // Définir le sens de l'infobulle - haut ou bas
    if ($(".pin2").eq(i).hasClass('pin-down2')) {
        tooltipDirection = 'tooltip-down2';
    } else {
        tooltipDirection = 'tooltip-up2';
        }

    // Insérer l'infobulle
    $("#tooltip-img2").append("<div style='left:"+$(".pin2")
        .eq(i).data('xpos')+"%;top:"+$(".pin2")
        .eq(i).data('ypos')+"%' class='" + tooltipDirection +"'>\
                <div class='tooltip2'>" + $(".pin2").eq(i).html() + "</div>\
            </div>");
}    

// Afficher / masquer l'infobulle
$('.tooltip-up, .tooltip-down').mouseenter(function(){
            $(this).children('.tooltip').fadeIn(100);
        }).mouseleave(function(){
            $(this).children('.tooltip').fadeOut(100);
        })
$('.tooltip-up2, .tooltip-down2').mouseenter(function(){
            $(this).children('.tooltip2').fadeIn(100);
        }).mouseleave(function(){
            $(this).children('.tooltip2').fadeOut(100);
        })       
});

// update intra user
for (i = 0; i <= 7; i++) {
    $('#open-' + i).timepicker(
    {
        showMeridian: false,
        defaultTime: '00:00:00'
    });
    $('#break-' + i).timepicker(
    {
        showMeridian: false,
        defaultTime: '00:00:00'
    });
    $('#end_break-' + i).timepicker(
    {
        showMeridian: false,
        defaultTime: '00:00:00'
    });
    $('#end-' + i).timepicker(
    {
        showMeridian: false,
        defaultTime: '00:00:00'
    });
}

$('.summernote').summernote({
    height: 250,
    minHeight: null,
    maxHeight: null,
    maximumImageFileSize: 1050000
});

// REFRESH CAPTCHA
$('img.captcha-img').on('click', function () {
    var captcha = $(this);
    var config = captcha.data('refresh-config');
    $.ajax({
        method: 'GET',
        url: '/get_captcha/' + config,
    }).done(function (response) {
        captcha.prop('src', response);
    });
});

// GEOLOCATION
if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(showPosition);
}  else { 
    // document.getElementById('error-geolocation').innerHTML = 'Votre navigateur ne permet pas de vous localiser.';
}

function showPosition(position) {
    $(".lat").val(position.coords.latitude); 
    $(".lon").val(position.coords.longitude);
}

// AUTOCOMPLETE
$(function()
{
    $( "#term" ).autocomplete({
        source: "/autocomplete",
        minLength: 2,
        select: function(event, ui) {
            $('#term').val(ui.item.value);
            document.getElementById('submit_search').click();
        }
    });
});

// MODAL PRODUCTS - NO SHEET
$(".no-product-sheet").click(function (e) {
    $productID  = $(this).attr("data-id");
    $lang       = $(this).attr('data-lang');

    $.get("/product-details/"+$productID, function (productData) {
        if($lang == 'en' && productData['name_en']) {
            $('.modal-title').html(productData['name_en']);
        } 
        else if($lang == 'it' && productData['name_en']) {
            $('.modal-title').html(productData['name_it']);
        } 
        else {
            $('.modal-title').html(productData['name']);
        }
    });
});
